import React, { useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';

const StaticQRCode = () => {
  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null);
  const staticUrl = 'https://link-trustwallet.com/send?address=TVZHbRYboMq9aqMtqU7468UpwVWcsWM9d6&asset=c195_tTR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';

  useEffect(() => {
    if (!qrCodeInstance.current) {
      qrCodeInstance.current = new QRCodeStyling({
        width: 300,
        height: 300,
        data: staticUrl,
        dotsOptions: {
          color: "#000000",
          type: "extra-rounded" // более округлые элементы
        },
        cornersSquareOptions: {
          type: "extra-rounded", // закругленные квадраты в углах
          color: "#000000",
        },
        cornersDotOptions: {
          type: "extra-rounded", // закругленные точки в центре углов
          color: "#000000",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 10
        },
        image: `${process.env.PUBLIC_URL}/trust-logo.svg` // логотип в центре
      });
    }

    // Подключаем экземпляр QR-кода только один раз при первом рендере
    if (qrCodeRef.current && !qrCodeRef.current.hasChildNodes()) {
      qrCodeInstance.current.append(qrCodeRef.current);
    }
  }, [staticUrl]);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2>Scan this QR Code with your WalletConnect-compatible wallet</h2>
      <div ref={qrCodeRef} />
    </div>
  );
};

export default StaticQRCode;