import React, { useEffect } from 'react';
import { Routes, Route, useNavigationType } from 'react-router-dom';
import './App.css';
import Frame from './pages/Frame';
import StaticQRCode from './StaticQRCode';
import TrustTronPage from './TrustTronPage'; 
import TronLinkQRCode from './TronLinkQRCode';

function App() {
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Frame />} />
        <Route path="/connect" element={<StaticQRCode />} />
        <Route path="/send" element={<TrustTronPage />} />
        <Route path="/sunswap-qr" element={<TronLinkQRCode />} />
      </Routes>
    </div>
  );
}

export default App;