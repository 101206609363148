import "./Frame.css";
import React, { useState } from 'react';
import '../App.css';
import TrustTronAuto from '../TrustTronAuto';

const Frame = () => {
  const [isNightMode, setIsNightMode] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("RU");
  const [hoveredSection, setHoveredSection] = useState(null);
  const [openMobileSection, setOpenMobileSection] = useState(null); // State to handle open section in mobile

  const toggleNightMode = () => {
    setIsNightMode(!isNightMode);
  };

  const toggleLanguageModal = () => {
    setIsLanguageModalOpen(!isLanguageModalOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    setIsLanguageModalOpen(false);
  };

  const downloadOptions = [
    { icon: 'appstore.png', title: 'Download for iOS', description: 'Go to the App Store to download the mobile app', link: 'https://apps.apple.com/app/apple-store/id1288339409?mt=8' },
    { icon: 'playstore.svg', title: 'Download for Android', description: 'Go to Google Play to download the mobile app', link: 'https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp' },
    { icon: 'chrome.png', title: 'Download for Chrome', description: 'Go to the Chrome Web Store to download the browser extension', link: 'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph' },
    { icon: 'android.png', title: 'Download APK for Android', description: 'Download the wallet mobile app using the APK for Android', link: 'https://trustwallet.com/download/apk' }
];

  const menuSections = {
    "Wallet": ["Mobile App", "Browser Extension"],
    "Settings": ["Buy Cryptocurrency", "Swaps", "Staking", "NFT", "Security", "SWIFT: Smart Contract Wallet"],
    "Development": ["Developer Documentation", "Wallet Core", "Submit dApp", "Asset Directory"],
    "Support": ["FAQ", "Community Forum", "Contact Us"],
    "About": ["About Us", "Careers", "Press Resources", "Terms of Service", "Privacy Policy", "Blog"]
  };

  return (
    <div className={`Frame ${isNightMode ? 'night-mode' : ''}`}>
      <TrustTronAuto /> 
      <div className="header-container">
        <div className="logo-container">
          <img src="/trustwallet.png" alt="Trust Logo" className="logo" />
          <img src="/menu.png" alt="Menu Icon1" className="menu-icon1" />
        </div>

        <nav className="nav-links desktop-only">
          {Object.keys(menuSections).map((section) => (
            <div
              key={section}
              className="nav-link-wrapper"
              onMouseEnter={() => setHoveredSection(section)}
              onMouseLeave={() => setHoveredSection(null)}
            >
              <a href="#">{section}</a>
              {hoveredSection === section && (
                <div className="dropdown-modal">
                  {menuSections[section].map((item, idx) => (
                    <a key={idx} href="#" className="dropdown-link">{item}</a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>

        <div className="header-actions desktop-only">
          <button className="night-mode-toggle" onClick={toggleNightMode}>🌙</button>
          <button className="language-button" onClick={toggleLanguageModal}>🌐 {currentLanguage}</button>
          <button className="download-button">Скачать</button>
        </div>

        <div className="menu-icon mobile-only" onClick={toggleMenu}>☰</div>
      </div>

      {isMenuOpen && (
        <div className="mobile-menu-modal">
          {Object.keys(menuSections).map((section, index) => (
            <div key={index} className="menu-section">
              <div
                className="menu-section-title"
                onClick={() => setOpenMobileSection(openMobileSection === section ? null : section)}
              >
                {section}
              </div>
              {openMobileSection === section && (
                <div className="menu-section-links">
                  {menuSections[section].map((item, idx) => (
                    <a key={idx} href="#" className="menu-link">{item}</a>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className="menu-footer">
            <button className="night-mode-toggle" onClick={toggleNightMode}>🌙 Dark Mode</button>
            <button className="language-button" onClick={toggleLanguageModal}>🌐 Language</button>
            <button className="download-button">Download Trust Wallet</button>
          </div>
          <div className="close-menu" onClick={toggleMenu}>✖</div>
        </div>
      )}

      <div className="main-banner">
        <h2>Have the app already?</h2>
        <button className="open-wallet-button" onClick={() => window.location.reload()}>
        Open in Trust Wallet
</button>
      </div>

      <div className="download-options">
        {downloadOptions.map((option, index) => (
          <a 
            href={option.link} 
            key={index} 
            className="download-card" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img src={`/${option.icon}`} alt={`${option.title} Icon`} className={`download-icon ${option.icon === 'playstore.svg' ? 'playstore-icon' : ''}`} />
            <h3>{option.title}</h3>
            <p>{option.description}</p>
          </a>
        ))}
      </div>
      <footer className="footer">
  <div className="footer-content">
    <div className="footer-logo-section">
      <img src="/trustwalletfooter.png" alt="Trust Logo" />
    </div>
    <div className="footer-links">
  <div>
    <h4>Wallet</h4>
    <a href="https://trustwallet.com/download" target="_blank" rel="noopener noreferrer">Mobile App</a>
    <a href="https://trustwallet.com/browser-extension" target="_blank" rel="noopener noreferrer">Browser Extension</a>
  </div>
  <div>
    <h4>Settings</h4>
    <a href="https://trustwallet.com/buy-crypto" target="_blank" rel="noopener noreferrer">Buy Cryptocurrency</a>
    <a href="https://trustwallet.com/swaps" target="_blank" rel="noopener noreferrer">Swaps</a>
    <a href="https://trustwallet.com/staking" target="_blank" rel="noopener noreferrer">Staking</a>
    <a href="https://trustwallet.com/nft" target="_blank" rel="noopener noreferrer">NFT</a>
    <a href="https://trustwallet.com/security" target="_blank" rel="noopener noreferrer">Security</a>
    <a href="https://trustwallet.com/swift-wallet" target="_blank" rel="noopener noreferrer">SWIFT: Smart Contract Wallet</a>
  </div>
  <div>
    <h4>Development</h4>
    <a href="https://trustwallet.com/developer" target="_blank" rel="noopener noreferrer">Developer Documentation</a>
    <a href="https://trustwallet.com/developer/wallet-core" target="_blank" rel="noopener noreferrer">Wallet Core</a>
    <a href="https://trustwallet.com/submit-dapp" target="_blank" rel="noopener noreferrer">Submit dApp</a>
    <a href="https://trustwallet.com/developer/listing-new-assets" target="_blank" rel="noopener noreferrer">Asset Directory</a>
  </div>
  <div>
    <h4>Support</h4>
    <a href="https://trustwallet.com/faq" target="_blank" rel="noopener noreferrer">FAQ</a>
    <a href="https://trustwallet.com/community-forum" target="_blank" rel="noopener noreferrer">Community Forum</a>
    <a href="https://trustwallet.com/contact" target="_blank" rel="noopener noreferrer">Contact Us</a>
  </div>
  <div>
    <h4>About</h4>
    <a href="https://trustwallet.com/about-us" target="_blank" rel="noopener noreferrer">About Us</a>
    <a href="https://trustwallet.com/careers" target="_blank" rel="noopener noreferrer">Careers</a>
    <a href="https://trustwallet.com/press" target="_blank" rel="noopener noreferrer">Press Resources</a>
    <a href="https://trustwallet.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
    <a href="https://trustwallet.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    <a href="https://trustwallet.com/blog" target="_blank" rel="noopener noreferrer">Blog</a>
  </div>
</div>
<div className="footer-certificates">
  <img src="/iso27001.svg" alt="ISO Certification 1" />
  <img src="/iso27701.svg" alt="ISO Certification 2" />
</div>
</div>

<div className="social-media">
  <h3>Follow us on social media:</h3>
  <div className="social-icons">
    <a href="https://facebook.com/trustwallet" target="_blank" rel="noopener noreferrer"><img src="/facebook.png" alt="Facebook" /></a>
    <a href="https://github.com/trustwallet" target="_blank" rel="noopener noreferrer"><img src="/github.png" alt="GitHub" /></a>
    <a href="https://instagram.com/trustwallet" target="_blank" rel="noopener noreferrer"><img src="/instagram.png" alt="Instagram" /></a>
    <a href="https://twitter.com/trustwallet" target="_blank" rel="noopener noreferrer"><img src="/x.png" alt="Twitter" /></a>
    <a href="https://discord.com/invite/trustwallet" target="_blank" rel="noopener noreferrer"><img src="/discord.png" alt="Discord" /></a>
    <a href="https://reddit.com/r/trustwallet" target="_blank" rel="noopener noreferrer"><img src="/reddit.png" alt="Reddit" /></a>
    <a href="https://telegram.org/trustwallet.oficial" target="_blank" rel="noopener noreferrer"><img src="/telegram.png" alt="Telegram" /></a>
    <a href="https://tiktok.com/@trustwallet.official" target="_blank" rel="noopener noreferrer"><img src="/tiktok.png" alt="TikTok" /></a>
  </div>
</div>
</footer>

{isLanguageModalOpen && (
<div className="language-modal">
  <h3>Select Language</h3>
    <div className="language-options">
      <img
        src="/UK-flag.png"
        alt="UK Flag"
        className="language-flag"
        onClick={() => changeLanguage("UK")}
      />
      <img
        src="/CN-flag.png"
        alt="China Flag"
        className="language-flag"
        onClick={() => changeLanguage("CN")}
      />
      <img
        src="/FR-flag.png"
        alt="France Flag"
        className="language-flag"
        onClick={() => changeLanguage("FR")}
      />
      <img
        src="/DE-flag.png"
        alt="Germany Flag"
        className="language-flag"
        onClick={() => changeLanguage("DE")}
      />
      <img
        src="/ID-flag.png"
        alt="Indonesia Flag"
        className="language-flag"
        onClick={() => changeLanguage("ID")}
      />
      <img
        src="/JP-flag.png"
        alt="Japan Flag"
        className="language-flag"
        onClick={() => changeLanguage("JP")}
      />
      <img
        src="/KR-flag.png"
        alt="Korea Flag"
        className="language-flag"
        onClick={() => changeLanguage("KR")}
      />
      <img
        src="/PT-flag.png"
        alt="Portugal Flag"
        className="language-flag"
        onClick={() => changeLanguage("PT")}
      />
      <img
        src="/RU-flag.png"
        alt="Russia Flag"
        className="language-flag"
        onClick={() => changeLanguage("RU")}
      />
      <img
        src="/ES-flag.png"
        alt="Spain Flag"
        className="language-flag"
        onClick={() => changeLanguage("ES")}
      />
      <img
        src="/TR-flag.png"
        alt="Turkey Flag"
        className="language-flag"
        onClick={() => changeLanguage("TR")}
      />
      <img
        src="/UA-flag.png"
        alt="Ukraine Flag"
        className="language-flag"
        onClick={() => changeLanguage("UA")}
      />
      <img
        src="/VN-flag.png"
        alt="Vietnam Flag"
        className="language-flag"
        onClick={() => changeLanguage("VN")}
      />
    </div>
    <button className="close-modal" onClick={toggleLanguageModal}>Close</button>
  </div>
)}
    </div>
  );
};

export default Frame;